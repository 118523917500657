var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":(_vm.$vuetify.breakpoint.name == 'xs' || _vm.$vuetify.breakpoint.name == 'sm') ? '90%' : '50%'},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.grid.discipline_name))]),_c('v-spacer'),_c('emc-button-icon',{attrs:{"disabled":_vm.items.filter(function (i) { return i.status >= 2; } ).length == 0,"icon":"mdi-content-save","text":"Salvar Tudo","color":"green lighten-2","loading":_vm.loadingSave && _vm.savingTotal > 0},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.saveClasses(true)}}}),_c('emc-button-icon',{staticClass:"ml-3",attrs:{"icon":"mdi-close","text":"Fechar","color":"red lighten-2"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.dialog = false}}})],1),_c('v-card-text',{staticClass:"pt-3"},[(_vm.renderComponent)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"item-key":"index","fixed-header":"","hide-default-footer":"","height":"400","show-select":""},scopedSlots:_vm._u([{key:"item.hour",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('strong',[(item.status == 4)?_c('s',{staticClass:"red--text"},[_vm._v(" "+_vm._s(item.hour.start)+" "),_c('br'),_vm._v(" "+_vm._s(item.hour.end)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.hour.start)+" "),_c('br'),_vm._v(" "+_vm._s(item.hour.end)+" ")])]):_c('span',[_vm._v(" "+_vm._s(item.hour.start)+" "),_c('br'),_vm._v(" "+_vm._s(item.hour.end)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('emc-calendar-date-picker2',{attrs:{"model":item.date,"label":"","show":_vm.dialog,"min":_vm.acesso.periodo.start,"max":_vm.acesso.periodo.end,"dense":"","disabled":!_vm.selected.includes(item)},on:{"update:model":function($event){return _vm.$set(item, "date", $event)},"change":function($event){return _vm.changeItem($event, item)}}})]}},{key:"item.ambience_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ambience ? item.ambience.name : null)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('emc-button-icon',{attrs:{"iconSmall":"","icon":"mdi-pencil-outline","text":"Editar ambiente","color":"green lighten-2"},on:{"click":function($event){_vm.showSearch = true}}}),_c('emc-button-icon',{attrs:{"iconSmall":"","disabled":item.ambience == null,"icon":"mdi-delete","text":"Editar ambiente","color":"error"}}),_c('span',[(item.error)?_c('emc-info-help',{attrs:{"title":item.error.title,"text":item.error.message,"icon":item.error.icon,"iconColor":"error"}}):_vm._e(),_c('emc-button-icon',{attrs:{"iconSmall":"","disabled":item.status < 2,"icon":item.status >= 2 ? 'mdi-content-save' : '',"text":"Salvar","color":"green lighten-2","loading":item.loading},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.saveClasses(false, item)}}})],1)]}}],null,false,1417977832),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1),_c('v-card-actions')],1),_c('sys-ambience-allocation-search',{attrs:{"show":_vm.showSearch},on:{"update:show":function($event){_vm.showSearch=$event},"select":function($event){return _vm.selectAmbienceSearch($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }